import { UnleashClient } from 'unleash-proxy-client';
import { logger } from './logger';

let unleashProxyClientInstance: UnleashClient | null = null;

export { UnleashClient };
export default () => {
  if (
    !process.env.NEXT_PUBLIC_UNLEASH_PROXY_URL ||
    !process.env.NEXT_PUBLIC_UNLEASH_PROXY_KEY
  ) {
    throw new Error('Unleash Variables not set');
  }
  if (!unleashProxyClientInstance && typeof window !== 'undefined') {
    unleashProxyClientInstance = new UnleashClient({
      url: process.env.NEXT_PUBLIC_UNLEASH_PROXY_URL,
      clientKey: process.env.NEXT_PUBLIC_UNLEASH_PROXY_KEY,
      appName: 'home-client'
    });
    logger.log('New unleash instance created');
  }

  return unleashProxyClientInstance;
};
