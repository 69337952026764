import { HTMLText, Topbar, UserSection } from '@morressier/ts';
import React, { useEffect, useState } from 'react';

import { useAuthContext } from 'context/AuthProvider';
import { useAuthFeatureFlagContext } from 'context/FeatureFlagProvider';
import { PusherHandler } from 'context/pusher';
import useAuth from 'hooks/useAuth';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { FEATURE_FLAGS } from 'utils/constants';
import { logEvent } from 'utils/eventTracking';
import { NEW_NOTIFICATION } from 'utils/pusher/notification';
import { useNotifications } from './hooks';
import { usePublisherTheme } from 'clients/internal/publisher';
import AMTrialButton from 'components/AMTrialButton';

const { publicRuntimeConfig } = getConfig();

interface TopBarWrapperProps {
  query: string;
  pathname: string;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
}

const TopBarWrapper: React.FC<TopBarWrapperProps> = props => {
  const userData = useAuthContext();
  const router = useRouter();
  const { publisher } = router.query;

  const features = useAuthFeatureFlagContext();

  const [isAuthClientOverlayEnabled, setIsAuthClientOverlayEnabled] =
    useState<boolean>(true);

  useEffect(() => {
    if (features[FEATURE_FLAGS.AUTH_CLIENT_OVERLAY] !== undefined) {
      setIsAuthClientOverlayEnabled(
        !!features[FEATURE_FLAGS.AUTH_CLIENT_OVERLAY]
      );
    }
  }, [features]);

  const { isSidebarOpen, setIsSidebarOpen, pathname } = props;
  const isMediumUp = useMediaQuery({ query: '(min-width: 721px)' });

  const { handleLogIn, handleSignUp } = useAuth();

  const isAuthenticated = Boolean(userData);
  const newNotification =
    isAuthenticated && NEW_NOTIFICATION(userData?.id as string);

  const {
    notifications,
    addNewNotification,
    markAllNotificationsSeen,
    markAllNotificationsRead,
    markSingleNotificationRead,
    loadMore
  } = useNotifications(isAuthenticated);

  const { data: theme, isLoading: themeLoading } = usePublisherTheme(
    publisher as string
  );

  const isTopBarLoading = !!publisher && themeLoading;

  const handleSearch = (term: string, _options: any) => {
    logEvent('SEARCH_STARTED_SUBMITTED_SEARCH', {
      term: term,
      pathName: router.pathname === '/' ? '/home' : router.pathname
    });
    // If search is coming from home and profiles show search all page
    router.push({
      pathname:
        props.pathname == '/' || props.pathname === '/profile/[id]'
          ? '/search/all'
          : props.pathname,
      query: {
        ...(term ? { term } : {})
      }
    });
  };

  const handleBrandClick = () => {
    logEvent('HOME_NAV_MORRESSIER_LOGO_CLICKED');
  };

  const onRightNavClicked = async () => {
    logEvent('HOME_RIGHT_NAV_CLICKED');
    toggleSidebar(false, true);
  };

  const toggleSidebar = (
    newSidebarState: boolean,
    isChildInteraction: boolean
  ) => {
    if (isSidebarOpen !== newSidebarState) {
      if (!isChildInteraction)
        logEvent(
          newSidebarState
            ? 'HOME_MENU_OPENED_CLICKED'
            : 'HOME_MENU_CLOSED_CLICKED'
        );
      setIsSidebarOpen(newSidebarState);
    }
  };

  const onNotificationsClick = (toggleFlyout: () => void) => {
    if (isMediumUp) {
      toggleFlyout();
    } else {
      window.location.href = '/notifications';
    }

    markAllNotificationsSeen();
    logEvent('HOME_NAV_NOTIFICATIONS_CLICKED', {
      unreadNotifications: notifications.unread
    });
    toggleSidebar(false, true);
  };

  const onSingleNotificationClick = (
    notification: Record<string, any> | undefined
  ) => {
    if (notification) {
      logEvent('NOTIFICATION_CLICKED', {
        type: notification.type
      });

      logEvent('HOME_NAV_NOTIFICATION_ITEM_CLICKED', { id: notification.id });

      if (!notification.read) {
        markSingleNotificationRead(notification.id);
      }

      router.push(notification.action);
    }

    toggleSidebar(false, true);
  };

  const onLogin = () => {
    // If SSO enabled open the new login overlay
    if (isAuthClientOverlayEnabled) {
      return handleLogIn();
    }

    // Redirect to the log in page
    const currentUrl = window.location.href;

    window.location.replace(
      `${publicRuntimeConfig.base_url}/modal?modal=signup-flow-forwarder&redirectRoute=${currentUrl}`
    );
  };

  const getTestId = () => {
    if (pathname.startsWith('/search/')) {
      return {
        desktop: 'top-nav-search-results-searchbar',
        mobile: 'top-nav-search-results-searchbar-mobile'
      };
    }
    return pathname === '/'
      ? { desktop: 'top-nav-searchbar', mobile: 'top-nav-searchbar-mobile' }
      : undefined;
  };

  const availablePaths =
    props.pathname === '/' ||
    props.pathname === '/search/all' ||
    props.pathname === '/search/events' ||
    props.pathname === '/search/sessions' ||
    props.pathname === '/search/research' ||
    props.pathname === '/search/call-for-abstracts' ||
    props.pathname === '/search/call-for-papers';

  return (
    <Topbar
      isAuthenticated={!!userData}
      isSidebarOpen={isSidebarOpen}
      logEvent={logEvent}
      onSearchSubmit={handleSearch}
      setIsSidebarOpen={state => toggleSidebar(state, false)}
      query={props.query}
      shouldCloseSidebarOnInteractions={false}
      onBrandClick={handleBrandClick}
      showDesktopSearchBarOnlyOnScroll={availablePaths}
      collapseMobileSearchBarOnScroll={availablePaths}
      useSearchbarV2
      searchBarTestId={getTestId()}
      hideSearchBar
      placeholder={'Discover scientific research'}
      theme={theme ?? {}}
      logoLink={`/home${theme ? `?publisher=${publisher}` : ''}`}
      isLoading={isTopBarLoading}
    >
      <UserSection
        logEvent={logEvent}
        isAuthenticated={!!userData}
        onMenuButtonClick={onRightNavClicked}
        authUserId={userData?.id ?? ''}
        fullName={userData?.full_name}
        pictureUrl={userData?.picture_url}
        onSignup={handleSignUp}
        pendingSubmissions={Number(userData?.submissions_open ?? 0)}
        copies={{
          notifications: {
            markAllAsRead: 'Mark all as read',
            emptyMessage: 'There are no updates at this time.',
            getNotificationBodyText: notification =>
              (
                <HTMLText additionalTags={['b']}>{notification.body}</HTMLText>
              ) as unknown as string
          },
          pendingSubmissions: {
            getButtonCopy: submissionsOpen => {
              if (submissionsOpen > 1) {
                return `Submit ${submissionsOpen} documents`;
              }

              if (submissionsOpen === 1) {
                return 'Submit 1 document';
              } else {
                return 'My Submissions';
              }
            },
            getFlyoutCopy: submissionsOpen => {
              if (submissionsOpen > 1) {
                return `You have ${submissionsOpen} posters that need to be submitted.`;
              }

              if (submissionsOpen === 1) {
                return 'You have 1 poster that needs to be submitted.';
              } else {
                return 'My Posters';
              }
            }
          }
        }}
        onLogin={onLogin}
        hideNotifications={false}
        userMenuNewTabAll={true}
        onNotificationsClick={onNotificationsClick}
        notifications={notifications}
        markAllAsRead={markAllNotificationsRead}
        loadMoreNotifications={loadMore}
        onSingleNotificationClick={onSingleNotificationClick}
        onPendingSubmissionsClick={() => {
          logEvent('OPENED_MY_CONTRIBUTIONS');
          window.open(`${origin}/submissions`, '_blank');
          toggleSidebar(false, true);
        }}
        hideSavedResearch
        theme={theme?.topbar}
      >
        <AMTrialButton
          theme={{
            primaryColor: theme?.topbar?.topbarIconBg,
            secondaryColor: theme?.topbar?.topbarTextColor,
            hoverColor: theme?.topbar?.topbarHover,
            bgHoverColor: theme?.topbar?.topbarHoverBg
          }}
        />
      </UserSection>
      {newNotification && (
        <PusherHandler
          channel={newNotification.channelName}
          event={newNotification.eventType}
          // @ts-ignore
          onUpdate={addNewNotification}
        />
      )}
    </Topbar>
  );
};

export default TopBarWrapper;
