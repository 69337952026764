import {
  createContext,
  Dispatch,
  useReducer,
  useContext,
  useEffect
} from 'react';
import PusherComponent from 'react-pusher';
import { PusherActions, startPusher } from './actions';

type PusherState = {
  hasPusherStarted: boolean;
};

const initialState = { hasPusherStarted: false } as PusherState;

const PusherContext = createContext<{
  state?: PusherState;
  dispatch: Dispatch<PusherActions>;
}>({ state: undefined, dispatch: () => null });

const reducer = (state: PusherState, action: PusherActions): PusherState => {
  switch (action.type) {
    case 'startPusher': {
      return { ...state, hasPusherStarted: true };
    }

    default:
      return initialState;
  }
};

const PusherProvider: React.FC = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PusherContext.Provider value={{ state, dispatch }}>
      {props.children}
    </PusherContext.Provider>
  );
};

const usePusherContext = () => {
  const context = useContext(PusherContext);
  if (!context.state || !context.dispatch) {
    throw new Error('PusherContext must be used within PusherProvider');
  }

  return context;
};

type Props = {
  channel: string;
  event: string;
  onUpdate: (props?: unknown) => void;
};

const PusherController: React.FC<Props> = props => {
  const { state, dispatch } = usePusherContext();

  useEffect(() => {
    if (!state?.hasPusherStarted) {
      dispatch(startPusher());
    }
  }, [dispatch, state?.hasPusherStarted]);

  return state?.hasPusherStarted ? <PusherComponent {...props} /> : null;
};

export const PusherHandler: React.FC<Props> = props => (
  <PusherProvider>
    <PusherController {...props} />
  </PusherProvider>
);
