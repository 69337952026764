import axios, { AxiosRequestConfig } from 'axios';
import { concatUrlWithParams, IParam } from 'utils/concatUrlWithParams';
import { getClientConfig } from 'utils/config';
import { BASE_PATH } from 'utils/constants';

const axiosInstance = axios.create({
  baseURL: getClientConfig().api_base
});

type BaseParams = { url: string; params?: IParam };
type IGet = { config?: AxiosRequestConfig; includeAuth?: boolean } & BaseParams;
type IPost = {
  url: string;
  data?: unknown;
  config?: AxiosRequestConfig;
  includeAuth?: boolean;
};

// call the Next.js api to get the auth token
const getAuthToken = async () => {
  const { data, status } = await axios.get(`${BASE_PATH}/api/me`, {
    params: { tokenOnly: true }
  });
  if (status === 200) {
    return data.access_token;
  }
  return null;
};

// if includeAuth is true, then we will try to get the auth token and set it
const includeAuthHeader = async (args: IGet | IPost) => {
  const { includeAuth } = args;
  if (includeAuth) {
    const token = await getAuthToken();
    setAuthHeader(token);
  }
};

const get = async <T = unknown>(args: IGet) => {
  await includeAuthHeader(args);
  return axiosInstance.get<T>(
    concatUrlWithParams(args.url, args.params),
    args.config
  );
};

const post = async <T = unknown>(args: IPost) => {
  await includeAuthHeader(args);
  return axiosInstance.post<T>(args.url, args.data, args.config);
};

const put = async <T = unknown>(args: IPost) => {
  await includeAuthHeader(args);
  return axiosInstance.put<T>(args.url, args.data, args.config);
};

const patch = async <T = unknown>(args: IPost) => {
  await includeAuthHeader(args);
  return axiosInstance.patch<T>(args.url, args.data, args.config);
};

const deleteMethod = async <T = unknown>(args: Omit<IPost, 'data'>) => {
  await includeAuthHeader(args);
  return axiosInstance.delete<T>(args.url, args.config);
};

const setAuthHeader = (token: string, header = 'Authorization' as const) => {
  if (token) {
    axiosInstance.defaults.headers.common[header] = `Bearer ${token}`;
  }
};

const removeAuthHeader = (header = 'Authorization' as const) => {
  axiosInstance.defaults.headers.common[header] = '';
};

const httpService = {
  get,
  post,
  put,
  patch,
  delete: deleteMethod,
  setAuthHeader,
  removeAuthHeader
};

export default httpService;
