import useSWR from 'swr';
import httpService from 'services/httpService';
import {
  IAvatarUploadURLResponse,
  IPartialUpdateProfile,
  IProfile,
  IProfileGeneralInfo,
  IResearch
} from 'typings/profile';
import axios from 'axios';

export const useProfile = (userId: string) => {
  const { data, error, ...rest } = useSWR<IProfile>(
    `/profile-service/v2/profiles/${userId}`
  );

  return {
    ...rest,
    data: data,
    error,
    hasError: Boolean(error),
    isLoading: !data && !error
  };
};

export const useResearch = (userId: string) => {
  const { data, error, ...rest } = useSWR<IResearch>([
    `/profile-service/v1/profiles/${userId}/research`
  ]);

  return {
    ...rest,
    data: data?.items || [],
    error,
    hasError: Boolean(error),
    isLoading: !data && !error
  };
};

export const getPrimaryAffiliation = async (userId: string) => {
  const { data } = await httpService.get<IProfile>({
    url: `/profile-service/v2/profiles/${userId}`,
    includeAuth: true
  });
  return data.primaryAffiliation;
};

export const updateResearch = (userId: string, ids: string[]) => {
  return httpService.put({
    url: `/profile-service/v1/profiles/${userId}/research`,
    data: { ids }
  });
};

export const updateProfile = (userId: string, data: IProfileGeneralInfo) => {
  return httpService.put({
    url: `/profile-service/v2/profiles/${userId}/general-info`,
    data
  });
};

export const updatePartialUserProfile = (
  userId: string,
  data: IPartialUpdateProfile
) => {
  return httpService.patch({
    url: `/profile-service/v1/profiles/${userId}`,
    data
  });
};

export const updatePartialProfileGeneralInfo = async (
  userId: string,
  data: IPartialUpdateProfile
) => {
  return httpService.patch<IProfile>({
    url: `/profile-service/v2/profiles/${userId}/general-info`,
    data
  });
};

export const uploadUserAvatar = async (
  userId: string,
  fileExtension: string,
  file: File
) => {
  try {
    const url = `/profile-service/v1/profiles/${userId}/avatar-upload-url?fileExtension=${
      fileExtension === 'jpg' ? 'jpeg' : fileExtension
    }`;

    const { data }: { data: IAvatarUploadURLResponse } = await httpService.post(
      { url }
    );

    data?.avatarUploadURL &&
      (await axios.put(data?.avatarUploadURL, file, {
        headers: {
          'Content-Type': file.type ?? '',
          'Cache-Control': 'public, max-age=86400'
        }
      }));
    return data?.avatarUploadURL.split('?')[0];
  } catch (err: any) {}
};
