import { initializePusher } from 'utils/pusher';

export const startPusher = () => {
  initializePusher();

  return {
    type: 'startPusher'
  } as const;
};

export type PusherActions = ReturnType<typeof startPusher>;
