import { KeyValueType } from 'typings/utils';

export const FEATURE_FLAGS = {
  AUTH_CLIENT_OVERLAY: 'platform--redirect-signin-auth-client',
  PROCEEDINGS: 'discovery--proceedings',
  DISABLE_AFFILIATION_EDITING: 'discovery--disable-affiliations-editing',
  NEW_PROFILE_PAGE: 'discovery--new-profile-page',
  SELF_SERVICE_EVENT_CREATION: 'platform--self-service-event-creation',
  PRODUCT_CATALOG_INTEGRITY_CHECK: 'platform--product-catalog-integrity-checks',
  PRODUCT_CATALOG_INTEGRITY_CHECK_BETA:
    'platform--product-catalog-integrity-check-beta',
  INTEGRATED_AUTH_FLOW: 'platform--integrated-auth-flow',
  STRIPE_PEER_REVIEW: 'platform--stripe-peer-review',
  STRIPE_PEER_REVIEW_ONE_OFF: 'platform--stripe-peer-review-one-off',
  AM_FREE_TRIAL: 'platform--abtract-managment-trial-20-submissions',
  SEARCH_ORG_BY_DOMAIN_V2: 'platform--search-organization-by-domain-v2',
  TASK_ORIENTED_HOME: 'commandos--task-oriented-home'
};

export const A_B_TEST_FLAG_NAMES = {
  [FEATURE_FLAGS.AUTH_CLIENT_OVERLAY]: 'single-sign-on',
  [FEATURE_FLAGS.PROCEEDINGS]: 'discovery--proceedings'
};

export const A_B_TEST_FLAG_VALUES = {
  [FEATURE_FLAGS.AUTH_CLIENT_OVERLAY]: {
    true: 'single-sign-on',
    false: 'single-sign-off'
  },
  [FEATURE_FLAGS.PROCEEDINGS]: {
    true: 'discovery--proceedings-on',
    false: 'discovery--proceedings-off'
  }
};

export type A_B_TEST_FLAG_TYPE = {
  'test-team-discovery-home-version'?: 'home-new-brand' | 'home-2018';
  'test-team-discovery-search-version'?: '1' | '2';
};

export const MAX_BIOGRAPHY_CHARACTER_LIMIT = 6000;
export const MAX_CHARACTER_LIMIT_MESSAGE = 'Maximum 6000 characters';
export const DEFAULT_TEXTAREA_ROW_COUNT = 6;
export const DEFAULT_TEXTAREA_HEIGHT = 160;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const HonorificsList: KeyValueType<string> = {
  doctor: 'Dr.',
  professor: 'Prof.',
  none: ''
};

export enum MESSAGES {
  EDIT_AFFILIATIONS_DISABLED = 'Editing affiliations is temporarily not available due to maintenance.'
}

export const institutionTypeText: any = {
  academic: 'Academic Institution',
  consortium: 'Consortia',
  corporate: 'Commercial Organisation',
  dist: 'Information Distributor',
  govt: 'Governmental Institution',
  health: 'Health Institution',
  hospital: 'Hospital',
  other: 'Other Institution Types',
  public: 'Public Institution',
  school: 'School'
};

export const BASE_PATH = '/home-client';
