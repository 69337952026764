import { useEffect, useState } from 'react';

import { logger } from './logger';
import unleashProxyClient from './unleashProxyClient';
import { identify } from 'utils/eventTracking';
import { useAuthContext } from 'context/AuthProvider';
import {
  A_B_TEST_FLAG_NAMES,
  A_B_TEST_FLAG_VALUES,
  FEATURE_FLAGS
} from './constants';
import getConfig from 'next/config';
import { IUnleashContext } from 'typings/utils';
import { TFeatureFlags } from 'context/FeatureFlagProvider';

const { publicRuntimeConfig } = getConfig();

const A_B_TEST_PREFIX = publicRuntimeConfig.A_B_TEST_PREFIX || '';

// single feature flag
export const useFeatureFlag = (featureFlagName: string) => {
  const client = unleashProxyClient();
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (!client) return;
    const updateState = () => {
      const enabled = client?.isEnabled(featureFlagName);
      setFlag(!!enabled);
    };

    client.on('update', updateState);
    client.on('ready', updateState);
  }, [client]);

  return flag;
};

// all feature flags
export const useUnleashed = (
  featureFlags: string[],
  context: IUnleashContext
) => {
  // features = { "discovery--new-home": true }
  const [features, setFeatures] = useState<TFeatureFlags>({});
  const unleash = unleashProxyClient();
  const userData = useAuthContext();

  useEffect(() => {
    if (unleash) {
      unleash.updateContext(context);
      // Just to make an immediate API call we have to restart the unleash
      unleash.stop();

      const updateFeatureFlags = () => {
        const A_B_Test_Flags: any = {};
        const newFeatures = featureFlags.reduce(
          (acc: TFeatureFlags, featureFlag: string) => {
            const isEnabled = unleash.isEnabled(featureFlag);
            acc[featureFlag] = isEnabled;

            const { name, enabled } = unleash.getVariant(featureFlag);
            if (enabled) {
              acc[featureFlag] = { name, enabled };
            }

            const featureFlagKey = `${A_B_TEST_PREFIX}${A_B_TEST_FLAG_NAMES[featureFlag]}`;
            if (A_B_TEST_PREFIX && A_B_TEST_FLAG_NAMES[featureFlag])
              A_B_Test_Flags[featureFlagKey] =
                A_B_TEST_FLAG_VALUES[featureFlag][`${isEnabled}`];

            return acc;
          },
          {}
        );
        userData &&
          identify(userData, { ...A_B_Test_Flags }, undefined, {
            newProfileEnabled: newFeatures[FEATURE_FLAGS.NEW_PROFILE_PAGE]
          });
        setFeatures(newFeatures);
      };

      unleash.on('ready', updateFeatureFlags);
      unleash.on('update', updateFeatureFlags);
      unleash.start();
    } else {
      logger.error('Unleash not initialized');
    }
    return () => {
      unleash?.stop();
    };
  }, []);

  return { unleash, features };
};
