import httpService from 'services/httpService';
import {
  MorressierNotification,
  NotificationResponse
} from 'typings/morressierNotification';
import { getQuery_ } from 'utils/query';

const BASE_URL = '/notifications/v1';

export const fetchNotifications = (payload: {
  offset: number;
  limit: number;
}) =>
  httpService
    .get<NotificationResponse>({
      url: `${BASE_URL}/${getQuery_({
        offset: payload.offset,
        limit: payload.limit
      })}`,
      includeAuth: true
    })
    .then(response => response.data);

export const markAllNotificationsRead = (): Promise<any> =>
  httpService
    .put({ url: `${BASE_URL}/read`, includeAuth: true })
    .then(response => response.data);

export const markAllNotificationsSeen = (): Promise<any> =>
  httpService
    .put({ url: `${BASE_URL}/seen`, includeAuth: true })
    .then(response => response.data);

export const markSingleNotificationRead = (id: string) =>
  httpService
    .put<MorressierNotification>({
      url: `${BASE_URL}/read/${id}`,
      includeAuth: true
    })
    .then(response => ({
      notifications: [response.data]
    }));
