import httpService from 'services/httpService';
import useSWR from 'swr';
import { IPublisherTheme } from 'typings/publisher';

export interface IGetPublisherIdByEventIdResponse {
  data?: {
    publisher: string;
  };
}
// This is used inside a vanilla js file, so we can't use swr neither hooks
export const getPublisherIdByEventId = (
  eventId: string
): Promise<IGetPublisherIdByEventIdResponse> =>
  httpService.get({ url: `v5/common/events/${eventId}/publisher-id` });

export const usePublisherTheme = (publisher: string) => {
  const { data, error, ...rest } = useSWR<IPublisherTheme>([
    publisher && `/v5/home-client/publisher/${publisher}/theme`,
    true // require auth token
  ]);

  return {
    ...rest,
    data: data,
    error,
    hasError: Boolean(error),
    isLoading: !data && !error
  };
};
