import { useEffect, useState } from 'react';

import {
  Button,
  ClearButton,
  media,
  MediaLargerEqual,
  MediaSmaller,
  Tooltip
} from '@morressier/ts';

import { faCalendarCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { useAuthContext } from 'context/AuthProvider';
import { useFeatureFlagContext } from 'context/FeatureFlagProvider';
import { FEATURE_FLAGS } from 'utils/constants';
import { identify, logEvent } from 'utils/eventTracking';
import { ModuleInterest, ModuleTrialModal } from './ModuleTrialModal';
import httpService from 'services/httpService';

type Theme = {
  primaryColor?: string;
  secondaryColor?: string;
  hoverColor?: string;
  bgHoverColor?: string;
};

type AMTrialButtonProps = {
  theme?: Theme;
};

const AMTrialButton = ({ theme }: AMTrialButtonProps) => {
  const featureFlags = useFeatureFlagContext();
  const flag = featureFlags[FEATURE_FLAGS.AM_FREE_TRIAL];
  const isVariant = typeof flag === 'object';
  const authUser = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [moduleInterests, setModuleInterests] = useState<ModuleInterest[]>([]);
  useEffect(() => {
    authUser &&
      isVariant &&
      flag.name &&
      identify(authUser, {
        [FEATURE_FLAGS.AM_FREE_TRIAL]: flag.name
      });
  }, [authUser, flag]);

  const fetchModuleInterests = () => {
    // Waith for user to be authenticated, otherwise request throws 401
    if (authUser?.id) {
      httpService
        .get<{ interests: ModuleInterest[] }>({
          url: `/v3/module-interest`,
          includeAuth: true
        })
        .then(res => setModuleInterests(res.data.interests));
    }
  };

  // We make the initial request here, so we have no loading states in the modal
  useEffect(() => {
    if (authUser && isVariant && flag.enabled) fetchModuleInterests();
  }, [authUser, isVariant, flag]);

  const onClick = () => {
    logEvent('AM_TRIAL_TOP_BAR_CLICKED_CTA');
    setIsModalOpen(true);
  };

  const text =
    isVariant && flag.name === 'A'
      ? 'Manage Your Abstracts for Free'
      : 'Try Out Submission Manager for Free';

  return isVariant && flag.enabled ? (
    <>
      {isModalOpen ? (
        <ModuleTrialModal
          onClose={() => setIsModalOpen(false)}
          moduleInterests={moduleInterests}
          revalidateModuleInterests={fetchModuleInterests}
        />
      ) : null}
      <ButtonWrapper theme={theme} onClick={onClick}>
        <MediaLargerEqual breakpoint="xxlarge">
          <Button
            round
            onClick={() => logEvent('AM_TRIAL_TOP_BAR_CLICKED_CTA')}
          >
            {text}
          </Button>
        </MediaLargerEqual>

        <MediaSmaller breakpoint="xxlarge">
          <Tooltip data-tool-tip={text}>
            <ClearButton aria-label={text}>
              <span className="icon">
                <FontAwesomeIcon icon={faCalendarCirclePlus} />
              </span>
            </ClearButton>
          </Tooltip>
        </MediaSmaller>
      </ButtonWrapper>
    </>
  ) : null;
};

const ButtonWrapper = styled.div`
  margin: 0px 10px;
  ${media.xxlarge`
    button {
      background: ${(props: { theme: Theme }) =>
        props?.theme?.primaryColor ?? 'var(--topbar-icon-bg)'};
      color: ${(props: { theme: Theme }) =>
        props?.theme?.secondaryColor ?? 'black'};

      &:hover {
        ${(props: { theme: Theme }) =>
          props?.theme?.hoverColor && props?.theme?.bgHoverColor
            ? `
              border-color: ${props?.theme?.hoverColor} !important;
              background-color: ${props?.theme?.bgHoverColor} !important;
              color: ${props?.theme?.hoverColor} !important; 
            `
            : ''}
      }
    }
  `}
  .icon {
    color: ${props => props.theme?.primaryColor ?? 'var(--topbar-icon-bg)'};
    font-size: 20px;
  }
`;

export default AMTrialButton;
