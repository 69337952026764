import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled, {
  ThemeProvider as StyledComponentsThemeProvider
} from 'styled-components';
import {
  ThemeNew as Theme,
  CATEGORY_NAME_MY_DOCUMENTS,
  CATEGORY_NAME_MY_CONFERENCES,
  CATEGORY_NAME_INSIGHTS,
  Footer,
  Reset
} from '@morressier/ts';
import { SwrProvider } from 'components/SwrProvider';
import { AppProps } from 'next/app';

import SidebarLayoutWrapper from 'containers/SidebarLayoutWrapper';
import TopBarWrapper from 'containers/TopBarWrapper';
import { AuthProvider } from 'context/AuthProvider';
import getConfig from 'next/config';
import { logger } from '../utils/logger';
import {
  FeatureFlagProvider,
  AuthFeatureFlagProvider
} from 'context/FeatureFlagProvider';
import '@morressier/ts/css/fonts.css';
import '@morressier/ts/css/tokens.css';
import '@morressier/ts/css/utils.global.css';
import Script from 'next/script';
import { getCookieValue } from 'utils/utils';
import { HotJarScript } from 'components/_scripts/HotJarScript';
import GlobalStyles from 'utils/GlobalStyle';
import { AuthClientWidget } from 'components/AuthClient';

import { getParsedQueryStrings } from '../utils/utils';
import {
  getPublisherIdByEventId,
  IGetPublisherIdByEventIdResponse
} from 'clients/internal/publisher';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@morressier/alembic';

export async function getInitialProps() {
  logger.log('_app.tsx getInitialProps - making publicRuntimeConfig available');
}

const AuthClientApp = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200000;
`;

export default function App({ Component, pageProps }: AppProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const router = useRouter();
  const { pathname = '/' } = router;
  const [sidebarInfo, setSidebarInfo] = useState<SidebarInfo>({
    openCategories: {
      [CATEGORY_NAME_MY_CONFERENCES]: true,
      [CATEGORY_NAME_MY_DOCUMENTS]: true,
      [CATEGORY_NAME_INSIGHTS]: true
    },
    insights: {
      pinnedData: 0,
      alerts: 0
    }
  });

  const setOpenCategories = (name: string, isOpen: boolean) => {
    setSidebarInfo(prevInfo => ({
      ...prevInfo,
      openCategories: {
        ...prevInfo.openCategories,
        [name]: isOpen
      }
    }));
  };

  const { publicRuntimeConfig } = getConfig();
  const publicPages = [
    '/affiliation-verification',
    '/verification-status',
    '/create-account',
    '/auth'
  ];
  const isPublicPage = publicPages.includes(router.pathname);

  return (
    <>
      <Script
        src={publicRuntimeConfig.DISPATCHER_SRC}
        strategy="beforeInteractive"
        onReady={() => {
          logger.debug('Dispatcher loaded');
          // @ts-ignore
          window.authEventBus = window.authEventBus || new Dispatcher();
          // @ts-ignore
          authEventBus.addListener('loginComplete', async details => {
            /*
             * For ORCID its sending cookie information in above 'details' variable
             * and for Google its sending cookie information in `details.setCookieInformation' variable
             * that is why we are having this conditional code 'details.setCookieInformation ?? details'
             */

            const sIdCookie = getCookieValue(
              details.setCookieInformation ?? details,
              'connect.sid'
            );

            // Set if cookie available
            if (sIdCookie) {
              document.cookie = `connect.sid=${sIdCookie}; path=/`;
            }

            const matchedPath = ['/create-account', '/auth'].find(path =>
              window.location.href.match(new RegExp(path))
            );

            if (matchedPath) {
              // Get the parsed query strings from the URL hash
              const qs = getParsedQueryStrings();

              const eventId = qs.eventId;
              let publisher = null;

              // If has eventId, get the publisherId for fetching the publisher theme
              if (eventId) {
                publisher = (
                  (await getPublisherIdByEventId(
                    eventId as string
                  )) as IGetPublisherIdByEventIdResponse
                )?.data?.publisher;
              }

              let redirectRoute: string;
              // Make sure redirectRoute starts with a "/"
              if (qs && qs.redirectRoute) {
                redirectRoute =
                  qs.redirectRoute.toString().charAt(0) === '/'
                    ? qs.redirectRoute.toString()
                    : '/' + qs.redirectRoute;
              } else {
                redirectRoute = `/home${
                  publisher ? `?publisher=${publisher}` : ''
                }`;
              }

              const redirectUrl = `${publicRuntimeConfig.base_url}${redirectRoute}`;
              // Replace the current URL with the redirect URL
              return window.location.replace(redirectUrl);
            }

            // @ts-ignore
            authEventBus?.dispatch('closeLoginModal');
            window.history.go(0);
          });
        }}
      />
      <script async src="https://js.stripe.com/v3/pricing-table.js" />
      <AuthClientWidget />
      <StyledComponentsThemeProvider theme={Theme}>
        <Reset />
        <GlobalStyles />
        <ChakraProvider theme={theme}>
          <SwrProvider>
            {!isPublicPage ? (
              <AuthFeatureFlagProvider>
                <AuthProvider>
                  <HotJarScript />
                  <FeatureFlagProvider>
                    <TopBarWrapper
                      isSidebarOpen={isSidebarOpen}
                      setIsSidebarOpen={setIsSidebarOpen}
                      query={(router.query.term as string) || ''}
                      pathname={pathname}
                    />
                    <SidebarLayoutWrapper
                      isSidebarOpen={isSidebarOpen}
                      sidebarInfo={sidebarInfo}
                      setIsSidebarOpen={setIsSidebarOpen}
                      setOpenCategories={setOpenCategories}
                      hideOn={[]}
                    >
                      <Component {...pageProps} />
                    </SidebarLayoutWrapper>
                    <Footer />
                  </FeatureFlagProvider>
                </AuthProvider>
              </AuthFeatureFlagProvider>
            ) : (
              <Component {...pageProps} />
            )}
          </SwrProvider>
        </ChakraProvider>
        <AuthClientApp id="auth-app-morressier" />
      </StyledComponentsThemeProvider>

      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-eu1.hs-scripts.com/25151219.js"
      />
    </>
  );
}
