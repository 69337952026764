import * as api from './notifications';
import { useCallback, useEffect, useState } from 'react';
import { typedBoolean } from 'utils/array';
import { logger } from 'utils/logger';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { logEvent } from 'utils/eventTracking';
import {
  MorressierNotification,
  NotificationResponse
} from 'typings/morressierNotification';
import { format } from 'date-fns';

const emptyCollection = {
  items: [],
  limit: 10,
  offset: 0,
  total: 0,
  unread: 0,
  unseen: 0
};

export const MeetingEventTypes = {
  RECEIVED: 'MEETING_INVITATION_RECEIVED',
  REFUSED: 'MEETING_INVITATION_REFUSED',
  CANCELLED: 'MEETING_INVITATION_CANCELED',
  ACCEPTED: 'MEETING_INVITATION_ACCEPTED'
};

const meetingInvitationBodyText = (
  notification: MorressierNotification
): string => {
  const { data, body } = notification;
  const getTimeTxt = (formattedDate: Date) =>
    formattedDate.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });

  // @ts-ignore - data object does not specify the types
  const date = new Date(String(data!.date));
  const timeTxt = getTimeTxt(date);
  const dateTime = `${format(date, 'LLL d')}, ${timeTxt}`;
  const formattedBody = body.replace(`##DATE_TIME##`, dateTime);

  return formattedBody;
};

// @TODO - date formatting needs to do in FE as of now, coz user profile does not store the users TZ
const formatBodyText = (
  notifications: MorressierNotification[]
): MorressierNotification[] =>
  notifications?.map(notification => {
    if (Object.values(MeetingEventTypes).includes(notification.type!)) {
      return {
        ...notification,
        body: meetingInvitationBodyText(notification)
      } as MorressierNotification;
    }

    return notification;
  });

export const useNotifications = (isAuthenticated: boolean) => {
  const [notificationsState, setNotificationsState] = useState<
    NotificationResponse | undefined
  >(undefined);

  const notifications = notificationsState?.collection
    ? {
        ...notificationsState.collection,
        items: formatBodyText(
          notificationsState.collection.items
            .map(id => notificationsState.notifications.find(n => n.id === id))
            .filter(typedBoolean)
        )
      }
    : emptyCollection;

  const mergeNotificationsState = (incomingData: NotificationResponse) =>
    setNotificationsState(oldState => {
      if (oldState) {
        const newItems = uniq([
          ...oldState.collection.items,
          ...incomingData.collection.items
        ]);
        return {
          collection: {
            ...incomingData.collection,
            items: newItems,
            limit: newItems.length
          },
          notifications: uniqBy(
            [...oldState.notifications, ...incomingData.notifications],
            n => n.id
          )
        };
      }
      return incomingData;
    });

  useEffect(() => {
    if (!isAuthenticated) return;

    api
      .fetchNotifications({ offset: 0, limit: 10 })
      .then(result => {
        setNotificationsState({
          collection: result.collection,
          notifications: result.notifications
        });
      })
      .catch(error => logger.log('error fetching notifications', error));
  }, [isAuthenticated]);

  const markAllNotificationsRead = useCallback(
    () =>
      api.markAllNotificationsRead().then(() => {
        logEvent('MARKED_ALL_AS_READ');
        setNotificationsState(notifState =>
          notifState
            ? {
                ...notifState,
                notifications: notifState.notifications.map(notification => ({
                  ...notification,
                  read: true
                }))
              }
            : undefined
        );
      }),
    []
  );

  const markAllNotificationsSeen = useCallback(
    () =>
      api.markAllNotificationsSeen().then(() => {
        logEvent('MARKED_ALL_AS_SEEN');
        setNotificationsState(notifState =>
          notifState
            ? {
                ...notifState,
                collection: {
                  ...notifState.collection,
                  unseen: 0
                }
              }
            : undefined
        );
      }),
    []
  );

  const markSingleNotificationRead = (id: string) =>
    api.markSingleNotificationRead(id).then(() =>
      setNotificationsState(notifState =>
        notifState
          ? {
              ...notifState,
              notifications: notifState.notifications.map(notification =>
                notification.id === id
                  ? {
                      ...notification,
                      read: true
                    }
                  : notification
              )
            }
          : undefined
      )
    );

  const addNewNotification = (notification: MorressierNotification) => {
    logEvent('ADD_NEW_NOTIFICATION');
    setNotificationsState(notifState =>
      notifState
        ? {
            ...notifState,
            collection: {
              ...notifState.collection,
              items: [notification.id, ...notifState.collection.items],
              unseen: notifState.collection.unseen + 1,
              unread: notifState.collection.unread + 1,
              total: notifState.collection.total + 1
            },
            notifications: [notification, ...notifState.notifications]
          }
        : undefined
    );
  };

  const loadMore = useCallback(() => {
    let shouldFetch = !notificationsState;

    if (notificationsState?.collection) {
      const { total, items } = notificationsState.collection;
      shouldFetch = items.length < total;
    }

    if (shouldFetch) {
      const page = notificationsState?.collection?.offset ?? 0;
      const totalPages = Math.floor(
        (notificationsState?.collection?.total ?? 0) / 10
      );

      return api
        .fetchNotifications({
          offset: page < totalPages ? page + 1 : 0,
          limit: 10
        })
        .then(result => {
          mergeNotificationsState(result);
          return result;
        });
    }

    return Promise.resolve();
  }, [notificationsState]);

  return {
    notifications,
    addNewNotification,
    markAllNotificationsRead,
    markAllNotificationsSeen,
    markSingleNotificationRead,
    loadMore
  };
};
