import { setUser } from '@sentry/nextjs';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';

import { OverlayPortal, Spinner } from '@morressier/ts';
import useAuth from 'hooks/useAuth';
import getConfig from 'next/config';
import useSWR, { KeyedMutator } from 'swr';
import { AuthUser } from 'typings/user';
import { BASE_PATH, FEATURE_FLAGS } from 'utils/constants';
import { useAuthFeatureFlagContext } from './FeatureFlagProvider';
const { publicRuntimeConfig } = getConfig();

interface CustomError extends Error {
  info?: any;
  status?: number | string;
}

export interface AuthContextProps extends AuthUser {
  mutate: KeyedMutator<AuthUser>;
}

type AuthProps = {
  children: ReactNode;
};

export const fetcher = async (url: string) => {
  const res = await fetch(url);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error: CustomError = new Error(res.statusText || 'Unknown error');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export function AuthProvider<AuthUser>({ children }: AuthProps) {
  const [isAuthClientOverlayEnabled, setIsAuthClientOverlayEnabled] =
    useState<boolean>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { handleLogIn } = useAuth();

  const features = useAuthFeatureFlagContext();

  useEffect(() => {
    if (features[FEATURE_FLAGS.AUTH_CLIENT_OVERLAY] !== undefined) {
      setIsLoading(false);
      setIsAuthClientOverlayEnabled(
        !!features[FEATURE_FLAGS.AUTH_CLIENT_OVERLAY]
      );
    }
  }, [features]);

  // Only call api when isLoading is false
  // useSWR’s first argument is the cache key.If we return null then useSWR will not preform the fetch.
  const { data, error, mutate } = useSWR<AuthUser | any>(
    () => (!isLoading ? `${BASE_PATH}/api/me` : null),
    fetcher
  );

  if (isLoading) {
    return (
      <AuthContext.Provider value={null || (undefined as any)}>
        <OverlayPortal>
          <Spinner />
        </OverlayPortal>
      </AuthContext.Provider>
    );
  }

  const currentUrl = window.location.href;

  if (!isLoading && error?.status > 400 && typeof window !== 'undefined') {
    // if profile or pricing page don't redirect to login page as this is available for non logged in user
    if (currentUrl.match(/\/profile|\/pricing/))
      return (
        <AuthContext.Provider
          value={data ? { ...data, mutate } : undefined || (undefined as any)}
        >
          {children}
        </AuthContext.Provider>
      );
    if (isAuthClientOverlayEnabled) {
      handleLogIn();
    } else {
      // use discovery to redirect to the login page
      window.location.replace(
        `${publicRuntimeConfig.base_url}/modal?modal=signup-flow-forwarder&redirectRoute=${currentUrl}`
      );
    }
  }

  data && setUser({ id: data.id, username: data.full_name });

  return (
    <AuthContext.Provider value={{ ...data, mutate } || (undefined as any)}>
      {data && children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  return context;
};
