import useSWR from 'swr';
import { ISubmissionSearchResultResponse } from 'typings/submission';
import { MyEventsResponse } from 'typings/event';

export const useRecentlyVisitedSubmissions = () => {
  const { data, error, ...rest } = useSWR<ISubmissionSearchResultResponse>([
    `/home-service/v1/recently-visited/submissions?limit=4`,
    true
  ]);

  return {
    ...rest,
    data: data,
    error,
    hasError: Boolean(error),
    isLoading: !data && !error
  };
};

export const useMyEvents = () => {
  const { data, error, ...rest } = useSWR<MyEventsResponse>([
    `/home-service/v1/common/me/events`,
    true
  ]);

  return {
    ...rest,
    data: data,
    error,
    hasError: Boolean(error),
    isLoading: !data && !error
  };
};
