import httpService from 'services/httpService';
import { SWRConfig } from 'swr';

const fetcher = (url: string, includeAuth?: boolean) =>
  httpService.get({ url, includeAuth }).then(res => res.data);

export const SwrProvider: React.FC = ({ children }) => (
  <SWRConfig
    value={{
      fetcher,
      errorRetryCount: 5,
      revalidateOnFocus: false,
      revalidateIfStale: false,

      onErrorRetry: error => {
        // Never retry on 401
        if (error?.response?.status === 401) return;
      }
    }}
  >
    {children}
  </SWRConfig>
);
