import { useFeatureFlagContext } from 'context/FeatureFlagProvider';
import { FEATURE_FLAGS } from 'utils/constants';

const useAuth = () => {
  const features = useFeatureFlagContext();

  const isIntegratedAuthEnabled = features[FEATURE_FLAGS.INTEGRATED_AUTH_FLOW];

  const handleLogIn = (data: Record<string, any> = {}) => {
    // @ts-ignore
    authEventBus.dispatch('openLoginModal', data);
  };

  const handleSignUp = (data: Record<string, any> = {}) => {
    if (isIntegratedAuthEnabled) data.path = 'account-signup';

    // @ts-ignore
    authEventBus.dispatch('openSignupModal', data);
  };

  return {
    handleLogIn,
    handleSignUp
  };
};

export default useAuth;
