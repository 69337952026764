import { ReactNode, useContext, createContext } from 'react';
import { useUnleashed } from 'utils/featureFlag';
import { useAuthContext } from './AuthProvider';
import { FEATURE_FLAGS } from 'utils/constants';
type FeatureFlagProviderProps = {
  children: ReactNode;
};

export type TFeatureFlags = Record<
  string,
  boolean | { enabled: boolean; name: string }
>;

// all possible feature flag names
const featuresFlags = Object.values(FEATURE_FLAGS);
// by default we set all feature flags to false
const defaultFeatureFlagValues = featuresFlags.reduce(
  (acc, value) => ({ ...acc, [value]: false }),
  {}
);
const FeatureFlagContext = createContext<TFeatureFlags>(
  defaultFeatureFlagValues
);

const AuthFeatureFlagContext = createContext<TFeatureFlags>(
  defaultFeatureFlagValues
);

export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
  const userData = useAuthContext();
  const { features } = useUnleashed(featuresFlags, {
    userId: userData?.id,
    email: userData?.email
  });

  return (
    <FeatureFlagContext.Provider value={features}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

export function AuthFeatureFlagProvider({
  children
}: FeatureFlagProviderProps) {
  const { features } = useUnleashed(featuresFlags, {});

  return (
    <AuthFeatureFlagContext.Provider value={features}>
      {children}
    </AuthFeatureFlagContext.Provider>
  );
}

export const useFeatureFlagContext = () => useContext(FeatureFlagContext);
export const useAuthFeatureFlagContext = () =>
  useContext(AuthFeatureFlagContext);
