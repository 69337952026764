import axios from 'axios';
import JsSHA from 'jssha';
import { AuthUser } from 'typings/user';
import { getPrimaryAffiliation } from 'clients/internal/profileService';
import { logger } from '../logger';
import { typedBoolean } from '../utils';

export const logEvent = async (
  event: string,
  properties?: {}
): Promise<any> => {
  if (process.env.NODE_ENV === 'development') {
    logger.debug('[segment][event]', event, properties);
    return true;
  } else {
    let anonymousId = '';
    if (window && window.analytics && window.analytics.user) {
      anonymousId = window.analytics.user().anonymousId();
    } else {
      logger.error(
        `[segment][event] No segment instance available, cannot track ${event}`
      );
    }
    return axios({
      method: 'post',
      url: '/log',
      baseURL: '/morressier-user-activity',
      data: {
        // @ts-ignore
        anonymousId,
        event,
        properties,
        context: {
          page: {
            url: window.location.href,
            referrer: window.document.referrer,
            path: window.location.pathname,
            search: window.location.search,
            title: window.document.title
          }
        }
      }
    });
  }
};

export const identify = async (
  authUser: AuthUser,
  traits?: {},
  options?: {},
  appcuesData?: {}
) => {
  if (process.env.NODE_ENV === 'development') {
    logger.debug(
      '[segment][identify user]',
      'traits',
      traits,
      'options',
      options
    );
  }
  if (window && window.analytics && window.analytics.user) {
    let primaryAffiliation;
    if (!primaryAffiliation) {
      primaryAffiliation = await getPrimaryAffiliation(authUser.id);
    }
    const {
      author_in = [],
      submissions_completed = 0,
      submissions_open = 0
    } = authUser;
    const isAuthor =
      author_in.length > 0 || submissions_completed > 0 || submissions_open > 0;
    window.analytics?.identify(getUserHash(authUser.id), {
      email: authUser.email,
      isAuthor,
      authUserId: authUser.id,
      ...appcuesData,
      hasPrimaryAffiliation: typedBoolean(primaryAffiliation?.affiliationId)
    });
    axios({
      method: 'post',
      url: '/identify',
      // prefixed in order to avoid adblockers blocking this request
      baseURL: '/morressier-user-activity',
      data: {
        anonymousId: window.analytics.user().anonymousId(),
        traits: {
          email: authUser.email,
          name: authUser.full_name,
          user_account_type:
            authUser.access_permissions &&
            authUser.access_permissions.analytics_dashboard
              ? 'premium'
              : 'free',
          ...traits
        },

        integrations: {
          Intercom: {
            user_hash: getUserHash(authUser.id)
          }
        },
        ...options
      }
    });
  } else {
    logger.error(
      '[segment][identify user] No segment instance available, cannot set user id'
    );
  }
};

const getUserHash = (userId: string): string => {
  const shaObj = new JsSHA('SHA-256', 'TEXT');
  shaObj.setHMACKey('leoj9As0baaJtMtA3nDXEsTg1ttrOQd19mdetcs7', 'TEXT');
  shaObj.update(userId);
  return shaObj.getHMAC('HEX');
};
