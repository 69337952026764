import { NextRouter } from 'next/router';
import qs from 'qs';

// Usually we would access the query object using router.query,
// but it is empty on first render. This method works in all cases.
export const getQuery = (router: NextRouter) => {
  return qs.parse(router.asPath.split('?')[1]);
};

export const getQuery_ = (
  params: Record<string, string | number | boolean | undefined>
) =>
  qs.stringify(params, {
    addQueryPrefix: true
  });
