import getConfig from 'next/config';
import Script from 'next/script';
import { logger } from 'utils/logger';
const { publicRuntimeConfig } = getConfig();

export const AuthClientWidget = () => {
  return (
    <Script
      src={publicRuntimeConfig.AUTH_APP_SRC}
      strategy="beforeInteractive"
      onReady={() => {
        logger.debug('AuthClientWidget loaded');
        // @ts-ignore
        AuthClientMorressier.init({
          isOpen: true,
          showCloseButton: false
        });
      }}
    />
  );
};
