// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { sentryAllowedEnvironments } from './utils/utils';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

/*
 * We have set the NODE_ENV variable to "production" for both the staging and production environments.
 * However, on localhost, we have set it to "development" to disable some features that are only needed during development.
 * To determine whether to enable Sentry, we check if the current environment is included in the list of allowed environments defined by "sentryAllowedEnvironments".
 * If the current environment is included, Sentry will be enabled.
 */
const currentEnvironment = process.env.NODE_ENV || '';
const enabled = sentryAllowedEnvironments.includes(currentEnvironment);

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://324475099786449e945e517c7d28f79a@o152170.ingest.sentry.io/6389181',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: samplingContext => {
    const transactionName = samplingContext.transactionContext?.name ?? '';
    if (transactionName.includes('/home')) {
      return 0.05;
    }

    return 0.0; // flat rate for all other transactions
  },
  release: '0.1.0',
  environment: process.env.APP_ENV,
  enabled

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
