import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import { parse as parseQueryString } from 'qs';
import { institutionTypeText } from './constants';

const { publicRuntimeConfig } = getConfig();

type FalsyType = false | null | undefined | '' | 0;
export function typedBoolean<ValueType>(
  value: ValueType
): value is Exclude<ValueType, FalsyType> {
  return Boolean(value);
}

export const getCookieValue = (cookieStr: string, cookie: string) => {
  return cookieStr.split(`${cookie}=`)[1].split(';')[0];
};

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
};

export const sentryCaptureException = (error: unknown) => {
  if (error && publicRuntimeConfig.node_env !== 'development')
    Sentry.captureException(error);
};

export const getInstitutionTypeText = (orgType: string | undefined) => {
  if (!orgType) return institutionTypeText.other;
  const mainDomain = orgType.split('/')[0];
  return institutionTypeText[mainDomain] || institutionTypeText.other;
};

// Function to get the raw query string from the URL hash
const getQueryStringFromOverlay = () => {
  const hash = window.location.hash;
  // Remove overlay trigger (e.g. '#signup?', '#login?', #account-signup? etc)
  // from the hash to get the query string
  const queryString = hash.replace(/#[a-zA-Z0-9\-\/]*\?/, '');
  return queryString;
};

// Function to parse the raw query string and return the parsed query strings
export const getParsedQueryStrings = () => {
  const rawQueryString = getQueryStringFromOverlay();
  const queryStrings = parseQueryString(rawQueryString);
  return queryStrings;
};

export const sentryAllowedEnvironments = ['production', 'staging'];

// Format the date range in a human-readable format
export const getFormattedDateRange = (startDate: Date, endDate: Date) => {
  const yearFormat = new Intl.DateTimeFormat('en', { year: 'numeric' });
  const monthFormat = new Intl.DateTimeFormat('en', { month: 'short' });

  const startYear = yearFormat.format(startDate);
  const endYear = yearFormat.format(endDate);
  const startMonth = monthFormat.format(startDate);
  const endMonth = monthFormat.format(endDate);
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      if (startDay === endDay) {
        return `${startMonth} ${startDay}, ${startYear}`;
      }
      return `${startMonth} ${startDay}-${endDay}, ${startYear}`;
    }
    return `${startMonth} ${startDay}-${endMonth} ${endDay}, ${startYear}`;
  }
  return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
};

export const getFormattedDate = (inputDate: Date) => {
  const yearFormat = new Intl.DateTimeFormat('en', { year: 'numeric' });
  const monthFormat = new Intl.DateTimeFormat('en', { month: 'short' });

  const endYear = yearFormat.format(inputDate);
  const endMonth = monthFormat.format(inputDate);
  const endDay = inputDate.getDate();

  return `${endMonth} ${endDay}, ${endYear}`;
};

export const isDeadlineApproaching = (inputDate: Date): boolean => {
  const now = Date.now();
  const deadline = new Date(inputDate).getTime();
  const oneWeek = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

  // Check if the input date is within 7 days after the current time
  return deadline > now && deadline <= now + oneWeek;
};
