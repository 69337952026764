import { useAuthContext } from 'context/AuthProvider';
import Script from 'next/script';

/**
 *  Hotjar
 */
export const HotJarScript = () => {
  const userData = useAuthContext();
  const userId = userData?.id;
  return (
    <>
      <Script id="hotjar-analytics" strategy="afterInteractive">
        {`
            window.hj('identify', '${userId}', {
              'test-team-discovery-home-version': 'home-new-brand'
            });
        `}
      </Script>
    </>
  );
};
