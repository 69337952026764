interface IRenderLinkProps {
  discoveryProps?: Record<string, unknown>;
  contentProps?: Record<string, unknown>;
  newTab?: boolean;
  href: string;
  children: React.ReactNode;
}

export const renderLink = (props: IRenderLinkProps) => {
  const targetAndRel = {
    ...(props.newTab ? { target: '_blank' } : undefined),
    ...(props.newTab ? { rel: 'noreferrer' } : undefined)
  };
  /*  
    standalone redirect, saving previous routing for reference
  <Link
    key={props.href}
    passHref
    target={props.newTab ? '_blank' : ''}
    rel={props.newTab ? 'noreferrer' : ''}
    {...props.discoveryProps}
  >
    {props.children}
  </Link> */

  return (
    <a
      key={props.href}
      href={props.href}
      {...targetAndRel}
      style={{
        textDecoration: 'none'
      }}
    >
      {props.children}
    </a>
  );
};
