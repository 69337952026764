import React from 'react';
import { SidebarLayout, color } from '@morressier/ts';
import { useMediaQuery } from 'react-responsive';
import { renderLink } from 'utils/misc';
import { useRouter } from 'next/router';
import { useAuthContext } from 'context/AuthProvider';
import { logEvent } from 'utils/eventTracking';
import { useMyEvents } from 'clients/internal/homeService';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${color('sidebarGrey')};
`;

interface SidebarLayoutWrapperProps {
  children: React.ReactNode;
  isSidebarOpen: boolean;
  sidebarInfo: SidebarInfo;
  hideOn: string[];
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  setOpenCategories: (name: string, isOpen: boolean) => void;
}

const SidebarLayoutWrapper: React.FC<SidebarLayoutWrapperProps> = props => {
  const { setIsSidebarOpen, setOpenCategories, isSidebarOpen, sidebarInfo } =
    props;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const { data } = useMyEvents();
  const hostingEvents = data?.hostingEvents || [];
  const attendingEvents = data?.attendingEvents || [];
  const reviewingEvents = data?.reviewingEvents || [];
  const userData = useAuthContext();

  const hasSubmissions =
    !!userData &&
    (!!userData.submissions_open || !!userData.submissions_completed);
  const pendingSubmissions = (userData && userData.submissions_open) || 0;
  const router = useRouter();
  const userEvents = [
    ...hostingEvents,
    ...reviewingEvents,
    ...attendingEvents
  ].reduce((userEvents, e) => ({ ...userEvents, [e.id]: e }), {});

  return (
    <Container>
      <SidebarLayout
        sidebarVersion="v2"
        isMobile={isTabletOrMobile}
        isSidebarOpen={isSidebarOpen && !props.hideOn.includes(router.pathname)}
        hasSubmissions={hasSubmissions}
        pendingSubmissions={pendingSubmissions}
        openCategories={sidebarInfo.openCategories}
        insights={sidebarInfo.insights}
        pathname={'/home'}
        logEvent={logEvent}
        renderLink={renderLink}
        setIsSidebarOpen={setIsSidebarOpen}
        setOpenCategories={setOpenCategories}
        showBorder
        userEvents={Object.values(userEvents)}
        userEmail={userData?.email}
        canCreateEvents={!!userData?.pub_coordinator_in?.length}
      >
        {props.children}
      </SidebarLayout>
    </Container>
  );
};

export default SidebarLayoutWrapper;
