import { ModalBody } from '@chakra-ui/modal';
import { Box, Flex, Text } from '@chakra-ui/react';
import { faCheck, faStar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Button,
  ButtonText,
  Modal,
  useToast
} from '@morressier/alembic';
import httpService from 'services/httpService';
import { IMorressierEvent } from 'typings/event';
import { logEvent } from 'utils/eventTracking';

export enum MODULES {
  SUBMISSION_MANAGER = 'Submission Manager',
  INTEGRITY_MANAGER = 'Integrity Manager',
  LIBRARY_MANAGER = 'Library Manager',
  EVENT_MANAGER = 'Event Manager'
}

export type ModuleInterest = {
  _id: string;
  module: MODULES;
};

interface Props {
  onClose: () => void;
  revalidateModuleInterests: () => void;
  moduleInterests: ModuleInterest[];
}

const modulesData: { module: MODULES; copy: string }[] = [
  {
    module: MODULES.SUBMISSION_MANAGER,
    copy: `Collect, review, and publish all submission formats, including
    posters, presentations, and proceedings, with easy-to-use research
    management tools.`
  },
  {
    module: MODULES.INTEGRITY_MANAGER,
    copy: `Deliver the highest quality content with embedded integrity checks
    at every stage of the publishing process.`
  },
  {
    module: MODULES.LIBRARY_MANAGER,
    copy: `Deliver the highest quality content with embedded integrity checks
    at every stage of the publishing process.`
  },
  {
    module: MODULES.EVENT_MANAGER,
    copy: `Deliver the highest quality content with embedded integrity checks
    at every stage of the publishing process.`
  }
];

export const ModuleTrialModal: React.FC<Props> = ({
  onClose,
  moduleInterests,
  revalidateModuleInterests
}) => {
  const toast = useToast();

  const createTrialEvent = async () => {
    logEvent('Start_AM_trial_clicked');

    try {
      const { event } = await httpService
        .post<{ event: IMorressierEvent }>({
          url: `/v3/events-backoffice/events/trial`
        })
        .then(({ data }) => data);

      location.href = `/conference/event/${event._id}/abm/overview?modal=am_trial_20`;
      onClose();
    } catch (err) {
      toast({
        title: 'Something went wrong. Please try again later',
        variant: 'error'
      });
    }
  };

  const isUserInterestedInModule = (module: MODULES): boolean => {
    return !!moduleInterests.find(interest => interest.module === module);
  };

  const notifyUserAboutModule = async (module: MODULES) => {
    logEvent('NOTIFY_FOR_TRIAL_CLICKED', { module });

    try {
      // User interest already exists, skip making a request
      if (!isUserInterestedInModule(module)) {
        await httpService.post({
          url: `/v3/module-interest`,
          data: { module }
        });
        revalidateModuleInterests();
      }

      toast({
        title: 'Your interest has been registered',
        variant: 'success'
      });
    } catch (err) {
      toast({
        title: 'Something went wrong. Please try again later',
        variant: 'error'
      });
    }
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      width="1300px"
      closeButtonProps={{ color: { base: 'black', md: 'white' } }}
    >
      <ModalBody display="flex" padding={0}>
        <Box
          padding={8}
          flexShrink={0}
          overflowX="auto"
          height="650px"
          width={{
            base: '100%',
            md: '650px'
          }}
        >
          <Text textStyle="headingSection" marginBottom={8}>
            Speed Up Your Workflows and Accomplish More
          </Text>
          <Text textStyle="headingLine" marginBottom={4}>
            Try Out Abstract Management for Free
          </Text>

          {/* TODO: replace with Alembic Card */}
          <Flex
            border="1px solid"
            borderColor="neutralSecondaryBorder"
            borderRadius="base"
            padding={6}
            gap={2}
            flexDirection="column"
            marginBottom={8}
          >
            <Flex alignItems="center" gap={2}>
              <Text textStyle="headingLine">Abstract Manager</Text>
              <Box style={{ fontWeight: '400 !important' }}>
                <Badge variant="attention">20 Submissions Free Trial"</Badge>
              </Box>
            </Flex>
            <Text>
              Speed up your workflow, collect, review, and share up to 20
              abstract submissions.
            </Text>
            <Box width="165px">
              <Button variant="primary" onClick={createTrialEvent}>
                Start Free Trial
              </Button>
            </Box>
          </Flex>

          <Text textStyle="headingLine" marginBottom={4}>
            Notify me when a trial is available for
          </Text>

          {modulesData.map(module => {
            const isInterested = isUserInterestedInModule(module.module);

            return (
              <Box key={module.module}>
                <Flex alignItems="center" gap={2}>
                  <Text textStyle="headingLine">{module.module}</Text>
                  <ButtonText
                    isActive={!!isInterested}
                    variant={isInterested ? 'success' : undefined}
                    onClick={() => notifyUserAboutModule(module.module)}
                    iconLeft={
                      <FontAwesomeIcon icon={isInterested ? faCheck : faStar} />
                    }
                  >
                    Notify me
                  </ButtonText>
                </Flex>
                <Text>{module.copy}</Text>
              </Box>
            );
          })}
        </Box>

        <Box
          flexGrow={1}
          flexShrink={1}
          display={{ base: 'none', md: 'flex' }}
          backgroundColor="surfaceBrandFill"
          borderTopRightRadius="0.35rem"
          borderBottomRightRadius="0.35rem"
        >
          <Box
            marginX="auto"
            backgroundImage="https://res.cloudinary.com/morressier-gmbh/image/upload/v1679477236/home-client/trial-modal.png"
            maxWidth="405px"
            width={{ base: '0', lg: '80%' }}
            height="100%"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="bottom"
          />
        </Box>
      </ModalBody>
    </Modal>
  );
};
